<template>
  <v-container class="sidebar--navigation">
 <v-row justify-center>
    <v-col cols="12">
      <v-card elevation="2">
        <v-row no-gutters>
          <v-col cols="4"
              :style="{ paddingTop: '100px' }"
          >
            <v-img
              v-if="getProductImage(product.attributes) != ''"
              :src="getProductImage(product.attributes)"
              height="200"
              contain
            ></v-img>
             <v-img
                v-else
                :src="require('@/assets/images/logos/shopify.png')"
                height="200"
                contain
            ></v-img>
          </v-col>
          <v-col cols="8">
            <v-card-title class="text-h5 grey lighten-2">
              <v-img
                max-height="35"
                max-width="35"
                :src="require('@/assets/images/logos/shopify.png')"
              ></v-img>
              &nbsp;Product Details
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text v-if="product.id !== undefined">
              <v-row>
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">Title: {{ product.title }}</p>
                  <p class="font-weight-bold">SKU: {{ product.sku }}</p>
                  <p class="font-weight-bold">Vendor: {{ product.vendor }}</p>
                  <p class="font-weight-bold" v-if="product.variations.length == 0">
                    Inventory Tracking:
                    <v-icon small :style="{ color: product.inventory_tracking === '1' ? '#077017' : 'red' }">
                      {{ product.inventory_tracking === '1' ? icons.mdiCheckCircle : icons.mdiCancel }}
                    </v-icon>
                      {{ product.inventory_tracking === '1' ? 'Tracked' : 'Not Tracked' }}
                  </p>
                  <p class="font-weight-bold">Published At: {{ product.published_at }}</p>
                </v-col>
                
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">Price: {{ product.price }}</p>
                  <p class="font-weight-bold">Stock Quantity: {{ product.stock_quantity }}</p>
                  <p class="font-weight-bold">Published At: {{ product.published_at }}</p>
                  <p class="font-weight-bold">Description: {{ product.description }}</p>
                  
                  <p class="font-weight-bold">Primary Colors:</p>
                  <v-row>
                    <v-col cols="12">
                      <template v-for="(value, key) in parseAttributes(product.attributes).options">
                        <p :key="key" class="font-weight-bold">
                          <template v-if="Array.isArray(value)">
                            {{ capitalizeFirstLetters(value.join(', ')) }}
                          </template>
                          <template v-else>
                            {{ capitalizeFirstLetters(value.values.join(', ')) }}
                          </template>
                        </p>
                      </template>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" md="6">
                 <template>
                      <h4>Metafields:</h4>
                      <ul>
                      <li v-for="(value, key) in parseMetafields(product.metafields)" :key="key">
                        <span class="font-weight-bold">{{ key }}:</span>
                          <template v-if="typeof value === 'string'">
                              <template v-if="value.startsWith('[') && value.endsWith(']')">
                                {{ JSON.parse(value.replace(/'/g, '')).join(', ') }}
                              </template>
                              <template v-else>
                                {{ value }}
                              </template>
                          </template>
                        </li>
                        </ul>
                      </template>
                 
                  </v-col>
              </v-row>

              <!-- Add more product details as needed -->
            </v-card-text>
            <v-card-text v-else>
              <div style="padding: 105px 0px" class="text-center">
                <h3>Etsy Shop not Connected!!</h3>
              </div>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
    </br>


    <v-card class="p-1">
      <v-card-title>
         Product Variations
      </v-card-title>

      <v-data-table :headers="headers" :items="product.variations" disable-pagination hide-default-footer>
        <template v-slot:body="{ items }">
          <tbody v-if="!loading_data">
            <tr v-if="!items.length">
              <td colspan="6" class="text-center" style="padding: 20px 0px">
                <img :src="require('@/assets/images/misc/no-data.png')" alt="" width="250px" />
                <p style="font-size: 25px">No Data Found!!</p>
              </td>
            </tr>
            <tr v-else v-for="item in items" :key="item.id">
              <td>
               #{{ item.shopify_id }}
              </td>
              <td style="padding: 4px"><img width="50px" :src="getProductImage(item.attributes)" alt="" /></td>
              <td style="max-width: 300px; font-size: 12px">
              <strong>{{ getVariantTitle(item) }}</strong>
              </td>
              <td>{{ item.sku }}</td>
              <td>
                <v-icon small :style="{ color: item.inventory_tracking === '1' ? '#077017' : 'red' }">
                      {{ item.inventory_tracking === '1' ? icons.mdiCheckCircle : icons.mdiCancel }}
                    </v-icon>
                      {{ item.inventory_tracking === '1' ? 'Tracked' : 'Not Tracked' }}
              </td>
              <td>
                  {{ item.stock_quantity}}
              </td>
              <td>
                {{item.price}}
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr>
              <td colspan="7" class="text-center" style="padding: 80px 0px">
                <img :src="require('@/assets/images/misc/loading.gif')" alt="" width="100px" />
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>

  </v-container>
</template>


<script>
import store from '@/store'
import { mdiChevronDown, mdiClose, mdiCheckCircle,
mdiCancel } from '@mdi/js'
export default {
  data() {
    return {
      productId: null,
      product: {},
      loading_data: false,
      shop: null,
      headers: [
        {
          text: 'Remote ID',
          value: 'remote_id',
        },
        {
          text: 'Image',
        },
        {
          text: 'Title',
          value: 'title',
        },
        {
          text: 'SKU',
          value: 'sku',
        },
        {
          text: 'Inventory Tracking',
          value: 'inventory_tracking',
        },
        {
          text: 'Inventory',
        },

        {
          text: 'Price',
        },
      ],
      icons: {
        mdiChevronDown,
        mdiClose,
        mdiCheckCircle,
        mdiCancel
      },
      variation_headers: [
        {
          text: 'Attributes',
        },
        { text: 'SKU' },
        { text: 'Price' },
        { text: 'Stock' },
      ],

    }
  },

  async mounted() {
    this.productId = this.$route.params.product_id;
    this.shop = store.getters.loggedInShop

    this.fetchShopifyProduct()
  },

  methods: {

     async fetchShopifyProduct() {
      try {
        this.loading_data = true
        let response = await axios.get(`admin/shops/${this.shop.id}/shopify-product/${this.productId}`, {
        })
        if (response.data.success) {
          this.product = response.data.product
          // this.is_first = false
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading_data = false
      }
    },
    
    getProductImage(attributes) {
      attributes = JSON.parse(attributes)
      let img_url = ''
      if (attributes.image_url) {
        if (Array.isArray(attributes.image_url)) {
          if (attributes.image_url[0]) {
            img_url = attributes.image_url[0]
          }
        } else {
          img_url = attributes.image_url
        }
      }
      return img_url
    },

     parseAttributes(attributes) {
      try {
        return JSON.parse(attributes);
      } catch (error) {
        console.error('Error parsing attributes:', error);
        return {};
      }
    },

    capitalizeFirstLetters(str) {
      return str
        .split(', ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(', ');
    },

   parseMetafields(metafields) {
      try {
        const parsedMetafields = JSON.parse(metafields);
        console.log(parsedMetafields)
        return parsedMetafields
      } catch (error) {
        console.error('Error parsing metafields:', error);
        return {};
      }
    },

    getVariantTitle(item)
    {
      return Object.entries(JSON.parse(item.attributes).variations)[0].join(': ')
    }

  },
 
}
</script>